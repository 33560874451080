.sumTable {
	&__table {
		border-collapse: collapse;
		border-spacing: 0;
		table-layout: fixed;
		width: 100%;
	}


	&__col {
		@include font(detail);
		border: 1px solid $baseColor;

		&--desc {
			width: 65%;

			.sumTable__summary & {
				@include font(detail);
				border: 0;
				@include font(detailHighlighted);
				padding: $base $base $base $base2;
				text-align: right;
			}

			.sumTable__header & {
				@include font(detail);
				@include font(detailHighlighted);
				padding: $base $base $base $base2;
				text-align: left;
			}
		}

		&--value {
			width: 35%;


			.sumTable__header & {
				@include font(detail);
				@include font(detailHighlighted);
				padding: $base $base $base $base2;
				text-align: right;
			}
		}

		tfoot &--value {
			@include font(thirdHeadline);
		}
	}


	&__input {
		@include font(base);
		font-family: $fontFamilyMain;
		font-variant-numeric: tabular-nums;
		background-color: inherit;
		border: 0;
		color: $baseColor;
		padding: $base $base $base $base2;
		width: 100%;

		&--value {
			text-align: right;
		}

		&:focus {
			outline: none;
		}
	}


	&__row {
		line-height: 0;
	}
}
