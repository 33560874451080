.block {
	&__text {
		margin-left: auto;
		margin-right: auto;
		// margin-bottom: $base2;

		&:last-child {
			margin-bottom: 0;
		}

		.block__title + & {
			margin-top: $base3;
		}


		.block--linkedTile &,
		.block--teaser &,
		.block--search & {
			@include font(firstHeadline);
		}

		&--important {
			@include font(thirdHeadline);
		}

		& + & {
			margin-top: $base2;
		}


		.block--video & {
			margin-top: $base2;
		}

		.block--video .block__title + & {
			margin-top: $base;
		}

		&--previewIntro {
			display: none;
		}

		&--contractIntro,
		&--contractOuttro {
			display: none;
		}


		blockquote {
			margin-left: 0;
		}
	}
}
