.block {
	&__content {
		position: relative;
		z-index: 1;
		display: block;
		max-width: $blockContentMaxWidth; //$mainxMaxWidth;
		width: 100%;
		margin: 0 auto;


		.block--slideshow.block--widthMax &,
		.block--fullImg &,
		.block--bgVideo &,
		.block--fullSize & {
			max-width: none;
		}


		.block--alignRight & {
			text-align: right;
		}


		.block--tilesWall > & {
			margin-bottom: -$base2;
		}


		.blockTilesWall--widthMax > & {
			max-width: none;
		}


		.block--publication & {
			height: auto;
			//margin-top: $base2;

			&::before {
				display: none;
			}
		}

		.block--video & {
			order: 1;
		}


		.overlay & {
			max-width: none;
		}
	}
}

