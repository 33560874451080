.applicationTab {
	position: relative;
	border-bottom: 1px solid currentColor;

	&__action {
		cursor: pointer;
		@include onHover {
			color: $hoverColorAlternate;
		}
	}

	&__label {
		.applicationTab.js-current & {
			@include font(thirdHeadline);
		}
	}


	&__progress {
		position: absolute;
		left: 0;
		top: 100%;
		width: 100%;
		height: $applicationTabProgressHeight;
		transform-origin: 0 0;
		backface-visibility: hidden;
		transform-style: preserve-3d;
		transform: scaleX(0%);
		background-color: $progressLineColor;

		.applicationTab.js-current & {
			background-color: $baseColor;
		}
	}
}
