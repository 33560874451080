
.account {
	position: relative;
	margin-left: 0;
	margin-right: 0;
	width: auto;
	background-color: $baseBgColor;


	&__block {
		padding: 0 $base2;
		max-width: none;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		visibility: hidden;
		opacity: 0;
		padding-top: $containerPaddingSmall;
		padding-bottom: $containerPaddingSmall;

		&.js-current {
			position: relative;
			visibility: inherit;
			opacity: 1;
		}
	}


	&__message {
		margin-bottom: $base5;

		p {
			margin-top: $base2;
		}
	}
}
