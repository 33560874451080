.page {
	&--applicationContract {
		font-family: Arial, sans-serif;
		font-feature-settings: normal;
		position: relative;
		padding-top: $base6;
		text-transform: none;
	}


	&__applicationContractLogo {
		position: absolute;
		right: 0;
		top: -$base4;
		width: auto;
		height: $base8;

	}


	&.js-leaving {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		width: 100%;
		opacity: 0;
		visibility: hidden;
	}


	&.js-entering {
		position: relative;
		z-index: 1;
	}


	&__form {
		* + & {
			margin-top: $base4;
		}
	}
}
