.applicationView {
	page-break-after: avoid;
	page-break-before: avoid;

	&__section {
		page-break-after: auto;
		page-break-inside: auto;

		// &:not(:first-of-type) {
		// 	page-break-before: always;
		// }
		// page-break-inside: avoid;

		& + & {
			margin-top: $base5;
		}

		&:not(:last-child) {
			page-break-after: always;
		}
	}

	&__sections {
		page-break-after: avoid;
		page-break-before: avoid;
	}

	&__sectionTitle {
		margin-bottom: $base3;
	}

	&__field {
		display: block;
		border-bottom: $borderSize solid $baseLineColor;
		padding-bottom: $base1;
		padding-top: $base1;
		page-break-after: auto;
		page-break-before: avoid;
		// page-break-inside: avoid;

		&:first-child {
			border-top: $borderSize solid $baseLineColor;
		}
	}

	&__fieldLabel {
		@include font(thirdHeadline);
		background-color: transparent;
		padding-right: $base2;
		padding-bottom: $base * 0.5;
		page-break-after: avoid;
		page-break-before: auto;
		page-break-inside: avoid;
		width: 99%;
	}

	&__fieldValue {
		@include font(base);
		background-color: transparent;
		padding-right: $base2;
		page-break-after: auto;
		page-break-before: avoid;
		// page-break-inside: avoid;
		width: 99%;
	}

	&__footer {
		@include clearfix();
		margin-bottom: $base4;
		display: block;
	}

	&__footerText {
		margin-top: $base4;
		width: 100%;
	}

	&__footerDate {
		border-bottom: 1px solid currentColor;
		float: left;
		margin-top: $base4;
		padding-bottom: $base4;
		page-break-inside: avoid;
		width: 30%;
	}

	&__footerSignature {
		border-bottom: 1px solid currentColor;
		float: right;
		margin-top: $base4;
		padding-bottom: $base4;
		page-break-inside: avoid;
		width: 30%;
	}

	&__imagefield {
		& + & {
			margin-top: $base2;
		}
	}

	&__imagefieldLabel {
		font-weight: bold;
		padding-bottom: $base * 0.5;
	}

	&__imageWrapper {
		padding-bottom: $base2;
	}

	&__image {
		display: none;
	}
}
