@import 'common/functions/index';
@import 'common/mixins/index';
@import 'app/functions/index';
@import 'app/mixins/index';

// config
@import 'kfn/app/config/font-faces';
@import 'kfn/app/config/font-names';
@import 'app-print/config/index';

// mixins
@import 'include-media/dist/_include-media';

// normalize, reset, helpers
@import 'common/generic/index';
@import 'app-print/generic/print-reset';

// blocks
@import 'app-print/blocks/index';
@import 'kfn/app-print/blocks/index';

@page {
	margin: 2cm 1.5cm 2cm 2cm;
}
