.block {
	&__title {
		@include font(firstHeadline);
		display: block;

		.block--tilesWall & {
			@include font(secondHeadline);
		}

		.block--video & {
			margin-top: $base2;
		}

		.block__titleImage + & {
			margin-top: $blockMobileVerticalPadding;

		}

		.block--tile.block--publication & {
			margin-top: $base2;
		}
	}
}
