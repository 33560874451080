$htmlFontSize: 100%;

$fontSizes: (
	base: (
		font-family: $fontFamilyMain,
		size: 9pt,
		font-weight: normal,
		line-height: 1.55,
	),
	firstHeadline: (
		font-family: $fontFamilySecond,
		size: 17pt,
		font-weight: normal,
		line-height: 1.2,
	),
	secondHeadline: (
		font-family: $fontFamilySecond,
		size: 15pt,
		font-weight: normal,
		line-height: 1.55,
	),
	thirdHeadline: (
		font-family: $fontFamilySecond,
		size: 13pt,
		font-weight: normal,
		line-height: 1.55,
	),
	detail: (
		font-family: $fontFamilyMain,
		size: 8pt,
		font-weight: normal,
		line-height: 1.3,
	),
	detailHighlighted: (
		font-family: $fontFamilySecond,
		size: 8pt,
		font-weight: normal,
		line-height: 1.3,
	),
	caption: (
		font-family: $fontFamilyMain,
		size: 8pt,
		font-weight: normal,
		line-height: 1.3,
	)
) !default;
