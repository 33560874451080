
// user generated content Style:

.ugc {
	img {
		max-width: 100%;
	}


	q,
	blockquote {
		quotes: "»" "«" "\00AB" "\00BB";
	}


	blockquote {
		margin: 0 0 0 1em;


		> p:first-child::before {
			content: open-quote;
		}


		> p:last-child::after {
			content: close-quote;
		}
	}

	cite {
		font-style: normal;
		font-weight: bold;


		&::before {
			content: '\2014';
			margin-right: 0.5em;
		}
	}

	blockquote + p > cite {
		display: block;
		margin-top: $base;
	}

	figure {
		&.video {
			position: relative;

			&::before {
				content: '';
				display: block;
			}


			> iframe {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

	ol {
		margin-left: $base2;

		> li {
			position: relative;
			margin-left: 1em;
			padding-left: 0.5em;
		}
	}

	ul {
		padding-left: 0;
		margin-bottom: $base2;

		&:last-child {
			margin-bottom: 0;
		}


		> li {
			position: relative;
			margin-left: 1em;
			padding-left: 0.5em;


			&::before {
				content: '–';
				position: absolute;
				left: -1em;
			}
		}
	}

	dt {
		font-weight: bold;
		font-style: normal;
	}


	dd {
		text-indent: 0;
		margin-left: 0;
	}

	dd + dd {
		margin-top: $base;
	}

	dd + dt {
		margin-top: $base2;
	}

	* + dl {
		margin-top: $base2;
	}


	iframe {
		max-width: 100%;
	}


	hr {
		display: block;
		height: 1px;
		border: 0;
		border-top: 1px solid currentColor;
		margin: 1em 0;
		padding: 0;
	}

	&.block__text--contract {
		p + p {
			margin-top: $base3;
		}


		hr {
			margin: 2em 0;
		}
	}


	h2 + * {
		margin-top: $base3;
	}

	* + h3 {
		margin-top: $base5;
	}

	h2 + h3 {
		margin-top: $base3;
	}

	h3 + * {
		margin-top: $base;
	}

	* + figure {
		margin-top: $base3;

	}

	h3 + figure {
		margin-top: $base2;
	}

	figure + * {
		margin-top: $base3;
	}
}


h1,
h2,
h3,
h4,
h5,
h6,
p,
figcaption,
li {
	.ugc &,
	&.ugc {
		max-width: $textMaxWidth;

		.block--alignCenter & {
			margin-left: auto;
			margin-right: auto;
		}

		.block--alignRight & {
			margin-left: auto;
		}
	}
}
