$duration: 0.1s !default;
$duration2: $duration * 2;
$duration3: $duration * 3;
$duration4: $duration * 4;
$duration5: $duration * 5;
$duration6: $duration * 6;
$duration7: $duration * 7;
$duration8: $duration * 8;
$duration9: $duration * 9;
$duration10: $duration * 10;


$easeLinear: linear;
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);

$buttonBorderOpacity: 0.4 !default;
$schoolBkgOpacity: 0.8 !default;

$videoHoverDuration: $duration2 * 1.5 !default;

$easeFunction: ease-out !default;

$hoverDuration: $duration2 !default;

$disabledFieldOpacity: 0.5 !default;

$slideshowFadeDuration: 0.4s !default;

$videoImgOpacity: 0.4 !default;
$videoImgOpacityHover: 0.2 !default;

$blockBgImgOpacity: 0.6 !default;
$blockBgImgCaptionOpacity: 0.7 !default;
