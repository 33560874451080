.applicationTabs {
	margin-right: $base3;

	&__items {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin-left: -$base2;
		margin-bottom: calc(-#{$base * 1.5} + #{$applicationTabProgressHeight});
	}

	&__item {
		flex-grow: 0;
		flex-shrink: 0;
		margin-bottom: calc(#{$base * 1.5} + #{$applicationTabProgressHeight});
		margin-left: $base2;
	}
}
