.form {
	position: relative;
	width: 100%;
	margin: auto;

	* + & {
		margin-top: $base4;
	}


	&__fields {
		position: relative;
		z-index: 1;
	}


	&__actions {
		position: relative;
		z-index: 10;
		width: 100%;
		left: 0;
		bottom: 0;
		margin-top: $base5;
		padding-bottom: $base5;

		.form[data-form="application/add"] & {
			padding-bottom: 0;
		}
	}

	&__actionsItems {
		display: flex;

		flex-direction: column;
	}


	&__actionsGroup {
		flex-shrink: 0;
		flex-grow: 0;
		width: 100%;

		& + & {
			margin-top: $base2;
		}
	}


	&__actionsGroupItems {
		display: flex;
		flex-direction: column;


		&--alignStart {
			justify-content: flex-start;
		}

		&--alignCenter {
			justify-content: center;
		}

		&--alignEnd {
			justify-content: flex-end;
		}


		.form__actionsGroup--accountExtraActions & {
			flex-direction: column;
			justify-content: flex-start;
		}
	}


	&__actionItem {
		flex-shrink: 0;
		flex-grow: 0;

		& + & {
			margin-top: $base2;
		}
	}
}
