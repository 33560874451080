
.previewList {

	.tagsList + & {
		margin-top: $base4;
	}

	&__item {
		border-bottom: 1px solid $baseLineColor;

		&:last-child  {
			border-bottom: 0;
		}
	}
}
