.applicationSection {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	visibility: hidden;
	opacity: 0;

	&.js-current {
		position:relative;
		visibility: inherit;
		opacity: 1;
	}

	&__description {
		margin-bottom: $base5;
	}

}
