$baseColor: #1a1a1a !default;
$baseBgColor: #f5f5f5 !default;
$baseLineColor: $baseColor !default;
$overlayFullBgColor: #000000 !default;
$linkColor: $baseColor !default;
$highlightColor: #c8e6fa !default;

$invertedColor: $baseBgColor !default;
$invertedBgColor: $baseColor !default;
$invertedLineColor: $baseBgColor !default;

$alternativeColor: $baseColor !default;
$alternativeBgColor: #ffffff !default;
$alternativeLineColor: $baseColor !default;

$videoBgColor: $baseColor !default;

$searchCoverColor: transparentize($overlayFullBgColor, 0.15) !default;

$headerBgColor: transparentize($baseBgColor, 0.1) !default;
$headerBgColorHighContrast: $baseBgColor !default;

$headerLineColor: transparentize($baseColor, 0.7) !default;

$footerColor: $baseColor !default;
$footerBgColor: #ffffff !default;
$subFooterColor: $invertedColor !default;
$subFooterBgColor: $invertedBgColor !default;

$logoListHoverBaseBgColor: #333333 !default;
$logoListHoverInvertedBgColor: #e5e5e5 !default;

$partnerBgColor: #e5e5e5 !default;

$errorColor: #cc3900 !default;

$notificationColor: $baseBgColor !default;
$notificationBgColor: transparentize($baseColor, 0.1) !default;

$checkIconColor: #00ac8a !default;
$progressLineColor: #9d9d9d !default;

$hoverColor: #e8e8e8 !default;
$hoverColorAlternate: #454545 !default;
$focusColor: $baseColor !default;
