@use 'sass:math';
.block {
	@mixin removeAdjacentSpace($scheme) {
		&--noBgImg.block--colorScheme#{$scheme}:not(.blockTilesWall__tile) + &--noBgImg.block--colorScheme#{$scheme},
		&--noBgImg.block--colorScheme#{$scheme}:not(.blockTilesWall__tile) + &--video.block--colorScheme#{$scheme},
		&--noBgImg.block--colorScheme#{$scheme}:not(.blockTilesWall__tile) + &--publication.block--colorScheme#{$scheme},
		&--video.block--colorScheme#{$scheme} + &--noBgImg.block--colorScheme#{$scheme}:not(.blockTilesWall__tile),
		&--publication.block--colorScheme#{$scheme} + &--noBgImg.block--colorScheme#{$scheme}:not(.blockTilesWall__tile) {
			padding-top: 0;
		}
	}

	@mixin removeExtraTileSpace($scheme) {
		&--tilesWall.block--colorScheme#{$scheme} {
			.block--noBgImg.block--colorScheme#{$scheme},
			.block--publication.block--colorScheme#{$scheme},
			.block--videoWithoutContent.block--colorScheme#{$scheme} {
				padding: 0;
			}
		}
	}

	max-width: none;
	display: block;
	position: relative;
	overflow: inherit;
	color: $baseColor;

	padding-top: $blockMobileVerticalPadding;
	padding-bottom: $blockMobileVerticalPadding;

	// remove adjacent margins when they have the same colors
	@include removeAdjacentSpace('Default');
	// @include removeAdjacentSpace('Inverted');
	@include removeAdjacentSpace('Alternative');

	&--slideshow {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;

		&.block--widthMax {
			padding-top: 0;
		}
	}

	&--fullImg {
		padding-left: 0;
		padding-right: 0;
	}

	&--bgVideo,
	&--fullSize {
		padding: 0;
	}

	&--noVerticalMargins {
		padding-bottom: 0;
		padding-top: 0;
	}

	&--noBgImg {
		overflow: visible;
	}

	&__titleImage + .blocks__subBlocks > &--noBgImg.block--colorSchemeDefault:first-child,
	&__titleImage + .blocks__subBlocks > &--video.block--colorSchemeDefault:first-child,
	&__titleImage + .blocks__subBlocks > &--publication.block--colorSchemeDefault:first-child {
		padding-top: 0;
	}

	.page--applicationEdit & {
		overflow: visible;
	}

	@include removeExtraTileSpace('Default');
	@include removeExtraTileSpace('Inverted');
	@include removeExtraTileSpace('Alternative');

	.block--tilesWall > .block__content > &--videoWithoutContent {
		padding: 0;
	}

	&--tile {
		margin-bottom: $base2;
	}

	&--colorSchemeDefault {
		color: $baseColor;
		background-color: $baseBgColor;
	}

	&--colorSchemeAlternative {
		color: $alternativeColor;
		background-color: $alternativeBgColor;
	}

	&--colorSchemeInverted {
		color: $invertedColor;
		background-color: $invertedBgColor;

		&:not(.block--tile):not(.block--bgImg):not(.block--slideshow) {
			margin-top: 1px;
			margin-bottom: 1px;
		}

		&:not(.block--tile):first-child {
			margin-top: 0;
		}
	}

	&--alignLeft {
		text-align: left;
	}

	&--alignCenter {
		text-align: center;
	}

	&--alignRight {
		text-align: right;
	}

	.overlay & {
		padding-left: 0;
		padding-right: 0;
	}

	.blocks--columns & {
		padding-top: $containerPaddingSmall;
		padding-bottom: $containerPaddingSmall;
	}

	.block--tilesWall & {
		padding-top: $base;
		padding-bottom: $base;
	}

	.blocks--pageActions &:nth-last-child(2) {
		padding-bottom: 0;
	}

	&--pageActions {
		padding-top: 0;
	}

	&--bgImg {
		min-height: math.div(100vw, $blockBgImageRatio);

		.block--tilesWall &,
		&.block--linkedTile,
		&.block--video,
		&.block--publication {
			min-height: 0;
		}
	}

	&__mainLink {
		&::before {
			content: '';
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
		}
	}

	&__pageTitle {
		@include font(firstHeadline);
		margin-bottom: $base3;

		&--contract {
			display: none;
		}
	}

	&__titleImage {
		text-align: center;
		// margin-bottom: $base7;

		img {
			width: 100%;
		}
	}

	&__subTitle {
		@include font(secondHeadline);
		display: block;

		.block--tilesWall & {
			@include font(base);
		}

		.block__titleImage + &,
		.block__title + & {
			margin-bottom: $base2;
		}
	}

	//NOTE: generalize likList!
	&__links {
		margin-left: auto;
		margin-right: auto;

		&:not(:first-child) {
			margin-top: $base3;
		}
	}

	&__linkItem {
		max-width: 100%;
		margin-bottom: $base;
	}

	&__logos {
		// max-width: $textMaxWidth;

		&:not(:first-child) {
			margin-top: $base5;
		}
	}

	&__icon {
		text-align: center;
		padding-top: $base2;

		span[data-type='svg'] {
			width: $base3;
		}
	}

	&__pagination {
		max-width: $blockContentMaxWidth;
		margin: $base3 auto $base2 auto;
	}

	&__partnerImage {
		margin-bottom: $base;

		img {
			width: 100%;
		}
	}
}

@import './block-elements/content';
@import './block-elements/bg-img';
@import './block-elements/title';
@import './block-elements/text';
@import './block-elements/full-img';
