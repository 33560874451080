.notifications {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 0;
	z-index: 20;


	&__item {
		position: absolute;
		width: 100%;
		bottom: 0;
		left: 0;
		opacity: 0;
		transform: translateY(0%);
	}
}
