.logo {
	color: $baseColor;
	display: block;
	height: $logoHeight;

	span[data-type="svg"] {
		height: 100%;

		svg {
			fill: $baseColor;
			height: 100%;
			width: auto;
		}
	}

	img {
		height: 100%;
		width: auto;
	}
}
