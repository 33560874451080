@use 'sass:math';
.applicationNav {
	padding: $base2 0;

	&__items {
		position: relative;
		overflow: hidden;
	}

	&__item {
		position: absolute;
		left: 0;
		top: 0;
		visibility: hidden;
		opacity: 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		&.js-current {
			position: relative;
			visibility: inherit;
			opacity: 1;
		}
	}

	&__actionWrapper {
		flex-grow: 0;
		flex-shrink: 0;
		width: math.div(100%, 3);

		&--back {
			text-align: center;
			padding-left: $base2;
			padding-right: $base2;
		}

		&:last-child {
			text-align: right;
		}
	}

	// &__actionIcon {
	// 	position: relative;
	// 	display: inline-block;
	// 	height: 1em;
	// }
}
