.block {
	&__fullCaption {
		padding: 0 $base2;
	}


	&__fullFigure {
		width: 100%;
	}


	&__fullImg {
		width: 100%;
	}
}
