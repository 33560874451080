@font-face {
	font-family: 'Soehne Buch';
	src:
		url('../fonts/soehne-web-buch.woff') format('woff'),
		url('../fonts/soehne-web-buch.woff2') format('woff2');
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: 'Soehne Halbfett';
	src:
		url('../fonts/soehne-web-halbfett.woff') format('woff'),
		url('../fonts/soehne-web-halbfett.woff2') format('woff2');
	font-style: normal;
	font-weight: bold;
}

